import React from 'react'
import '../../css/about.css'
const About = () => {
  return (
    <div className='about'>
      <div className="dot_image">
        <img src={require('../../images/Dot.png')} alt="" />
      </div>
      <h2 className='heading'>ABOUT DEALDASH</h2>
      <div className='about_container'>
        <div className="image">
          <img className='main_image' src={require('../../images/perc.jpg')} alt=''></img>
        </div>
        <div className='sub_container'>
          <div className='about_text'>
            <img className='checkbox_icon' src={require('../../images/checkbox-circle-fill.png')} alt=''></img>
            <div className="text">
              <h4>DISCOVER INCREDIBLE LOCAL DEAL</h4>
              <p>Our user-friendly platform effortlessly connects you with incredible offers from your favorite local businesses, sparing you from endless scrolling on multiple apps and websites.</p>
            </div>
          </div>
          <div className='about_text'>
            <img className='checkbox_icon' src={require('../../images/checkbox-circle-fill.png')} alt=''></img>
            <div className="text">
              <h4>SIMPLIFY YOUR DEAL-HUNTING</h4>
              <p>With a few clicks, you can unlock a world of savings from dining out to pampering yourself at the salon, keeping your car in top shape, or getting those essential home services.</p>
            </div>
          </div>
          <div className='about_text'>
            <img className='checkbox_icon' src={require('../../images/checkbox-circle-fill.png')} alt=''></img>
            <div className="text">
              <h4>EMPOWER LOCAL BUSINESSES</h4>
              <p>Deal Dash isn't just for users seeking deals; it's also a powerful tool for local merchants. We provide them with the means to attract and retain customers effectively.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About