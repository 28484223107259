import React from 'react'
import '../../css/WhyJoinDealDash.css'

const WhyJoinDealDash = () => {
  return (
    <div className='join_container'>
      <div className="desc">
        <h2>WHY JOIN DEAL DASH</h2>
        <p>Deal Dash isn't just for user seeking best deals; its a game changer for local shop owners too.</p>
      </div>
      <div className="sub_container">
        <div className="card">
          <div className="text">
            <h4>Visibility Boost</h4>
            <p>Local shop owners gain more exposure to potential customers, attacking new ones and retaining old ones.</p>
          </div>
        </div>
        <div className="card">
          <div className="text">
            <h4>Cost-Effective Marketing</h4>
            <p>Deal Dash provides a targeted, budget-friendly marketing solution, reaching the right audience.</p>
          </div>
        </div>
        <div className="card">
          <div className="text">
            <h4>Promotional Flexibility</h4>
            <p> Local shops can create various promotions to experiment and engage customers creatively.</p>
          </div>
        </div>
        <div className="card">
          <div className="text">
            <h4>Loyalty Building</h4>
            <p>Customers collect stamps, fostering loyalty and encouraging repeat business.</p>
          </div>
        </div>
        <div className="card">
          <div className="text">
            <h4>Community Support</h4>
            <p>By joining Deal Dash, shops contribute to the community's vibrancy and receive local support.</p>
          </div>
        </div>
      </div>
      <div className="image">
        <img src={require('../../images/MultipleMobile2.png')} alt="" />
      </div>
      <p className='summary'> In summary, Deal Dash empowers local shop owners to thrive in the digital age, boosting revenue and customer engagement while strengthening their local presence.</p>
    </div>
  )
}

export default WhyJoinDealDash
