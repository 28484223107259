import React from 'react'
import '../../css/Welcome.css'

const Welcome = () => {
    return (
        <div className="welcome_container">
            <div className="line1_image">
                <img src={require('../../images/Line.png')} alt="" />
            </div>
            <h2>WELCOME TO DEALDASH !!</h2>
            <div className="line2_image">
                <img src={require('../../images/Line.png')} alt="" />
            </div>
            <div className="desc">
                <div className="text">
                    <h1>Discover Local Deals and Unlock Discounts Every Time with Deal Dash</h1>
                    <p>Tired of the endless search for the best local deals? Deal Dash simplifies your deal-hunting experience. Discover the latest offers from your favorite local shops and services effortlessly.</p>
                    <div className="icons">
                        <img src={require('../../images/Welcome/Google_Play.png')} alt="" />
                        <img src={require('../../images/Welcome/Apple_Play.png')} alt="" />
                    </div>
                </div>
                <div className="image">
                    <img src={require('../../images/Welcome/Mobile.png')} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Welcome