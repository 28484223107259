import React from 'react'
import '../../css/Service.css'

const Service = () => {
  return (
    <div className='service_container'>
      <h2>Discover Deals across following Services</h2>
      <div className="sub_container">
        <div className="card">
          <img src={require('../../images/Services/restaurants.png')} alt="" className='icon' />
          <h4>Take Away</h4>
        </div>
        <div className="card">
          <img src={require('../../images/Services/salon.png')} alt="" className='icon' />
          <h4>Salon</h4>
        </div>
        <div className="card">
          <img src={require('../../images/Services/car_repair.png')} alt="" className='icon' />
          <h4>Car Repair</h4>
        </div>
        <div className="card">
          <img src={require('../../images/Services/e_comm.png')} alt="" className='icon' />
          <h4>E-Commerce</h4>
        </div>
        <div className="card">
          <img src={require('../../images/Services/hidden_gems.png')} alt="" className='icon' />
          <h4>Hidden Gem</h4>
        </div>
        <div className="card">
          <img src={require('../../images/Services/travel.png')} alt="" className='icon' />
          <h4>Travel</h4>
        </div>
        <div className="card">

          <img src={require('../../images/Services/outdoor_activity.png')} alt="" className='icon' />
          <h4>Outdoor Activity</h4>
        </div>
        <div className="card">
          <img src={require('../../images/Services/home_service.png')} alt="" className='icon' />
          <h4>Home Service</h4>
        </div>
      </div>
      <div className="image">
      <img className='mobileimage3' src={require('../../images/Services/Mobile3.png')} alt="" />
        <img className='mobileimage2' src={require('../../images/Services/Mobile2.png')} alt="" />
        <img className='mobileimage1' src={require('../../images/Services/Mobile1.png')} alt="" />
        <img className='mobileimage4' src={require('../../images/Services/Mobile4.png')} alt="" />
      </div>
    </div>
  )
}

export default Service
