import React, { useState } from 'react'
import '../../css/ConnectWithUs.css'
import emailjs from 'emailjs-com';


const ConnectWithUs = () => {

    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        PhoneNumber: '',
        Industry: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    function sendEmail(e) {
        e.preventDefault();
        
        const emailData = {
            to_name: 'Recipient Name',
            from_name: 'Sender Name',
            ...formData
        };
        //This is important, i'm not sure why, but the email won't send without it
        console.log(emailData)
        emailjs.send('service_xkmzbnw', 'template_wkoigyc', emailData, 'M7ion82HXH_ar-LL9')
          .then((result) => {
              window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
          }, (error) => {
              console.log(error.text);
          });
      }
    return (
        <div className="connect_container">
            <div className="sub_container">
                <div className="image">
                    <img src={require("../../images/Connect.png")} alt="" />
                </div>
                <div className="desc">
                    <h2>CONNECT WITH US</h2>
                    <p>
                        Are you ready to join Deal Dash and unlock a world of
                        opportunities for your local business? We're thrilled to have you
                        on board.
                    </p>
                    <p>
                        Please complete the following details, and one of our team members
                        will get in touch with you to guide you through the onboarding
                        process.
                    </p>
                </div>
            </div>
            <div className="input_container">
                <form onSubmit={sendEmail}>
                    <div className="input_subContainer">
                        <label className="input_labelField">
                            Name *<br></br>
                            <input type="text" className="input_textField" value={formData.Name} onChange={handleChange} name="Name" placeholder='John Robert' />
                        </label>

                        <label className="input_labelField">
                            Email *<br></br>
                            <input type="text" className="input_textField" value={formData.Email} onChange={handleChange} name="Email" placeholder='john@gmail.com' />
                        </label>

                        <label className="input_labelField">
                            Phone Number *<br></br>
                            <input type="text" className="input_textField" value={formData.PhoneNumber} onChange={handleChange} name="PhoneNumber" placeholder='+91 9632587412' />
                        </label>

                        <h4>Select industry *</h4>

                        <div className="checkbox_container">
                            <label>
                                <input type="radio" value="Restaurant" onChange={handleChange} name="Industry" />
                                Restaurant
                            </label>
                            <label>
                                <input type="radio" value="E-Commerce" onChange={handleChange} name="Industry" />
                                E-Commerce
                            </label>
                            <label>
                                <input type="radio" value="Salon" onChange={handleChange} name="Industry" />
                                Salon
                            </label>
                            <label>
                                <input type="radio" value="Car Repair" onChange={handleChange} name="Industry" />
                                Car Repair
                            </label>
                            <label>
                                <input type="radio" value="Hidden Gems" onChange={handleChange} name="Industry" />
                                Hidden Gems
                            </label>
                            <label>
                                <input type="radio" value="Home Services" onChange={handleChange} name="Industry" />
                                Home Services
                            </label>
                            <label>
                                <input type="radio" value="Travel" onChange={handleChange} name="Industry" />
                                Travel
                            </label>
                            <label>
                                <input type="radio" value="Outdoor Activities" onChange={handleChange} name="Industry" />
                                Outdoor Activities
                            </label>
                        </div>
                    </div>
                    <button id="button" type="submit">
                        <img src={require('../../images/Button.png')} alt="" />
                        Send Message
                    </button>
                </form>
                <p>We look forward to working together to boost your business and enrich the local shopping experience.</p>
            </div>
        </div>
    );
}

export default ConnectWithUs