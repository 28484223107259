import React from 'react'
import '../../css/ConvenienceAndSavings.css'

const ConvenienceAndSavings = () => {
  return (
    <div className='savings_container'>
      <div className="desc">
        <h2>UNLEASHING CONVENIENCE AND SAVINGS</h2>
        <p>Deal Dash puts power in your hands. It enhances your daily life by making local deals more accessible, convenient, and costeffective.</p>
      </div>
      <div className="image">
        <img src={require('../../images/SavingsAndConvenience.png')} alt="" />
      </div>
      <div className="sub_container">
        <div className="card">
          <img src={require('../../images/comment.png')} alt="" />
          <h4>HIDDEN TREASURES</h4>
          <p>Find local gems you might have missed.</p>
        </div>
        <div className="card">
          <img src={require('../../images/comment.png')} alt="" />
          <h4>EFFORTLESS SAVINGS</h4>
          <p>Easily discover local deals, saving you time and money.</p>
        </div>
        <div className="card">
          <img src={require('../../images/comment.png')} alt="" />
          <h4>LOYALTY REWARDS</h4>
          <p> Collect stamps for savings  on future orders.</p>
        </div>
        <div className="card">
          <img src={require('../../images/comment.png')} alt="" />
          <h4>SIMPLE ORDERING</h4>
          <p>Order food, schedule services with a few taps.</p>
        </div>
        <div className="card">
          <img src={require('../../images/comment.png')} alt="" />
          <h4>REAL TIME REWARDS</h4>
          <p>Stay informed with order status and ETAs.</p>
        </div>
        <div className="card">
          <img src={require('../../images/comment.png')} alt="" />
          <h4>EMPOWER YOURSELF</h4>
          <p>Support your local community.</p>
        </div>
      </div>
    </div>
  )
}

export default ConvenienceAndSavings
