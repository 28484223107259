import React from 'react'
import About from './About'
import Service from './Service'
import ConvenienceAndSavings from './ConvenienceAndSavings'
import WhyJoinDealDash from './WhyJoinDealdash'
import Footer from './Footer'
import SavingsAndLoyaltyProgram from './SavingsAndLoyaltyProgram'
import Welcome from './Welcome'
import ConnectWithUs from './ConnectWithUs'

const Home = () => {
  return (
    <div>
      <Welcome />
      <About />
      <Service />
      <SavingsAndLoyaltyProgram />
      <WhyJoinDealDash />
      <ConvenienceAndSavings />
      <ConnectWithUs />

      <Footer />
    </div>
  );
}

export default Home
