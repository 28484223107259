import React from 'react'
import '../../css/SavingsAndLoyaltyProgram.css'

const SavingsAndLoyaltyProgram = () => {
  return (
    <div className='program_container'>
      <div className="desc">
        <h2>THE SAVING SOLUTION:</h2>
        <h2> AND OUR UNBEATABLE LOYALTY PROGRAM</h2>
        <p>We believe in rewarding your loyalty. Our exclusive Loyalty Program is designed to make your experience with us even more rewarding and cost-effective.</p>
      </div>
      <div className="sub_container">
        <div className="image">
          <img src={require('../../images/SavingsAndLoyaltyProgram/Mobile1.png')} alt="" />
        </div>
        <div className="text">
          <div className="card">
            <img src={require('../../images/SavingsAndLoyaltyProgram/1.png')} alt="" />
            <h4>Unlock Your Reward</h4>
            <p>Once you've collected 10 stamps within the month, you unlock a fantastic 50% discount on your next purchase from any of our participating merchants.</p>
          </div>
          <div className="card">
            <img src={require('../../images/SavingsAndLoyaltyProgram/2.png')} alt="" />
            <h4>Avail Services</h4>
            <p>When you avail a service from any of our trusted merchants, you're on your way to earning rewards.</p>
          </div>
        </div>
      </div>
      <div className="sub_container">
        <div className="text">
          <div className="card">
            <img src={require('../../images/SavingsAndLoyaltyProgram/3.png')} alt="" />
            <h4>Accumulate 10 Stamps</h4>
            <p>Your goal is to collect 10 stamps within a single month. The more stamps you collect, the closer you get to amazing discounts.</p>
          </div>
          <div className="card">
            <img src={require('../../images/SavingsAndLoyaltyProgram/4.png')} alt="" />
            <h4>Enjoy Your Savings</h4>
            <p>Enjoy the benefits of your loyalty! It's our way of saying "Thank You" for choosing us and being a valued part of our community.</p>
          </div>
          <div className="card">
            <img src={require('../../images/SavingsAndLoyaltyProgram/5.png')} alt="" />
            <h4>Collect Digital Stamps</h4>
            <p>For each service availed, you receive a digital stamp in our mobile app. Keep track of your stamps in the app</p>
          </div>
        </div>
        <div className="image">
          <img src={require('../../images/SavingsAndLoyaltyProgram/Mobile2.png')} alt="" />
        </div>
      </div>
    </div>
  )
}

export default SavingsAndLoyaltyProgram
